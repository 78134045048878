import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Card,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { ReactComponent as GoogleIcon } from "assets/icons/Google-Button.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  root: {
    width: 480,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: "80%",
    boxSizing: "border-box"
  },
  cardTitle: {
    font: "400 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20
  },
  googleButton: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    border: "1px solid #707070",
    borderRadius: "4px",
    height: 56,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#00000098"
  },
  facebookButton: {
    background: "#3B5998 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    borderRadius: "4px",
    height: 56,
    marginTop: 20,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#FFFFFF",
    "&:hover": {
      background: "#3B5998 0% 0% no-repeat padding-box"
    }
  },
  dividerEmail: {
    marginTop: 20,

    textAlign: "left",
    font: "400 16px/21px Roboto",
    letterSpacing: 0,
    color: "#000000",
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    width: "100%"
  },
  emailTextField: {
    marginTop: 20
  },
  emailIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  emailButton: {
    height: 56,
    marginTop: 20,
    textTransform: "none",

    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    }
  },
  actions: {
    marginTop: 20,
    textAlign: "left",
    font: "400 14px/19px Roboto",
    letterSpacing: 0,
    color: "#3886FC",
    "& a": {
      textDecoration: "none",
      color: "#3886FC"
    }
  }
});
function RecoverCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { confirmSignUp, setConfimed, profile } = props;
  const [vCode, setVcode] = useState("");
  return (
    <Card className={classes.root}>
      {" "}
      <span className={classes.cardTitle}>
        Ingresa tu codigo de verificacion
      </span>
      <hr className={classes.separator} />
      <div className={classes.dividerEmail}>
        <span>
          Te hemos enviado un codigo de verificacion a tu correo. Porfavor
          ingresa el codigo para continuar.
        </span>
      </div>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Codigo de verificacion"
        className={classes.emailTextField}
        value={vCode}
        onChange={function(e) {
          setVcode(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.emailIcon}>
              <VerifiedUserIcon color="inherit" />
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={classes.emailButton}
        disableElevation
        onClick={function() {
          console.log(profile.email);
          confirmSignUp(profile.email, vCode)
            .then(function(res) {
              console.log(res);
              setConfimed(profile.email, "");
              history.push("/");
            })
            .catch(function(err) {
              console.log("err");
            });
        }}
      >
        {" "}
        Confirmar correo
      </Button>
      <div className={classes.actions}>
        <span>
          <a
            href="/registrar"
            onClick={e => {
              e.preventDefault();
              history.push("/registrar");
            }}
          >
            Reenviar Codigo
          </a>
        </span>
      </div>
    </Card>
  );
}

export default RecoverCard;
