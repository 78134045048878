import React from "react";
import { setCookie, getCookie } from "cookie";
import Header from "components/Header";
import Footer from "components/Footer";
import SetPasswordCard from "./SetPasswordCard";

import { makeStyles } from "@material-ui/core";
import Home from "screens/Home";
import Auth from "screens/Auth";
import Search from "screens/Search";
import NotFound from "screens/NotFound";
import Login from "screens/Login";
import Register from "screens/Register";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    // display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  headerContainer: {},
  footerContainer: {
    bottom: 0,
    flexshrink: 0
  },

  recoverCard: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  content: {
    minHeight: "calc(100vh - 318px)",
    marginTop: 50,
    marginBottom: 100
  }
});
const routes = [
  { path: "/auth", type: "private", exact: true, component: Auth },
  {
    path: "/search",
    type: "dual",
    exact: true,
    public: Search,
    private: () => {
      return "private search";
    }
  },
  // { path: "/search", type: "public", exact: true, component: Search },

  { path: "/ingresar", type: "public", exact: true, component: Login },
  { path: "/registrar", type: "public", exact: true, component: Register },
  // { path: "/recuperar", type: "public", exact: true, component: Recover },
  {
    path: "/test",
    type: "dual",
    exact: true,
    public: Home,
    private: Home
  },
  { type: "dual", public: NotFound, private: NotFound }
];

function Recover(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Header />

        <div className={classes.content}>
          <div className={classes.recoverCard}>
            {/* <Router routes={routes} basename={props.computedMatch.path} /> */}
            <SetPasswordCard />
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Recover;
