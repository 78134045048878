import {
  RESET_PROFILE,
  LOGIN_CHECK,
  SET_PROFILE
} from "store/const/actionTypes";

import { get, set, deepClone } from "utils";
const initState = {
  loginChecked: false,
  loggedIn: false,
  confirmed: false,
  fullName: "",
  email: "",
  forgotPassword: false
};
export default function search(state = initState, action) {
  switch (action.type) {
    case SET_PROFILE:
      const { path, value } = action;
      console.log(path, value);
      const nextState = deepClone(state);
      const res = set(nextState, path, value);
      return res !== null ? nextState : state;
    case LOGIN_CHECK:
      return state;

    case RESET_PROFILE:
      return initState;

    default:
      return state;
  }
}
