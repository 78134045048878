import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;

  return { bDate: form.birthdate, step: form.step };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 6 });
    },

    setBDate(value) {
      dispatch({ type: "UPDATE_FORM", path: "birthdate", value });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
