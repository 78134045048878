import React, { useContext, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Card,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "70%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: { display: "flex" },
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  },
  label: {
    textAlign: "left",
    font: "500 16px/29px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  content: { display: "flex" },
  illustrationContainer: {},
  illustration: {
    verticalAlign: "middle",
    maxWidth: "30vw"
  },
  stepsContainer: {
    flex: 1,
    textAlign: "left",
    font: "400 20px/29px Roboto",
    letterSpacing: 0,
    color: "#000000",
    textShadow: "0px 3px 6px #00000029",
    "& ol": {
      marginBlockStart: "1em",
      marginBlockEnd: "0em",
      opacity: 0.6
    },
    marginLeft: 10
  },
  helper: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle"
  },
  stepsHelper: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  info: {
    textAlign: "left",
    font: "500 16px/26px Roboto",
    letterSpacing: 0,
    color: "#000000",
    opacity: 0.6
  }
}));

function StepCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const appConstants = useContext(Constants);
  const { employmentTypes, otherDescriptions } = appConstants;
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [income3Months, setIncome3Months] = useState("");
  const { step } = props;

  // console.log(selected);

  // if (step < 9) {
  //   history.push(`./${step}`);
  // }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={100}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>
          Aplicacion completa y recibida
        </span>
        <hr className={classes.separator} />
        {/* <div className={classes.fill}></div> */}
        <div className={classes.questions}>
          <div className={classes.content}>
            <div className={classes.illustrationContainer}>
              <span className={classes.helper}></span>
              <img
                src="/assets/card/thankyou.svg"
                alt="Nuestros 3 pasos"
                className={classes.illustration}
              />
            </div>
            <div className={classes.stepsContainer}>
              <span className={classes.helper}></span>

              <div className={classes.stepsHelper}>
                <div className={classes.cardTitle}>
                  Gracias por tu aplicacion
                </div>
                <br />
                <span className={classes.info}>
                  {" "}
                  Recibiras un correo de confirmacion con tus respuestas e
                  informacion sobre como observar el status de tu aplicación.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default StepCard;
