import { connect } from "react-redux";
import View from "./view";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
