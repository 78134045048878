import { UPDATE_FORM, RESET_FORM } from "store/const/actionTypes";
import { get, set, deepClone } from "utils";

const empInitState = {
  employmentType: "",
  companyName: "",
  roleName: "",
  isWorkingStudent: "",
  otherDescription: ""
};
const initState = {
  step: 1,
  type: "",
  budget: "",
  address: {
    address1: "",
    address2: "",
    address3: ""
  },
  personal: {
    fullName: "",
    email: "",
    phone: ""
  },
  birthdate: "",
  livingSituation: {
    type: "",
    monthlyPayment: ""
  },
  employment: empInitState,
  income: {
    monthlyIncome: "",
    income3Months: ""
  }
};
export default function search(state = initState, action) {
  switch (action.type) {
    case UPDATE_FORM: {
      const { path, value } = action;
      const nextState = deepClone(state);
      const res = set(nextState, path, value);
      return res !== null ? nextState : state;
    }
    case RESET_FORM: {
      const nextState = deepClone(initState);
      return nextState;
    }
    case "SET_EMP_TYPE": {
      const nextState = deepClone(state);
      nextState.employment = deepClone(empInitState);
      nextState.employment.employmentType = action.value;
      return nextState;
    }
    case "SET_EMP_NAME": {
      const nextState = deepClone(state);
      nextState.employment.companyName = action.value;
      return nextState;
    }
    case "SET_EMP_ROLE": {
      const nextState = deepClone(state);
      nextState.employment.roleName = action.value;
      return nextState;
    }

    case "SET_EMP_WS": {
      const nextState = deepClone(state);
      nextState.employment.isWorkingStudent = action.value;
      return nextState;
    }

    case "SET_OTHER_DESC": {
      const nextState = deepClone(state);
      nextState.employment.otherDescription = action.value;
      return nextState;
    }

    case "NEXT_STEP": {
      const nextState = deepClone(state);
      nextState.step = Math.max(nextState.step, action.value);
      return nextState;
    }

    default:
      return state;
  }
}
