import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    width: "71%",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0.5px solid #696969",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: 30,
    textShadow: "none"
  },
  cardTitle: {
    textAlign: "left",
    font: "700 20px/33px Roboto",
    letterSpacing: 0,
    color: "#000000",
    position: "relative",
    textShadow: "none",
    opacity: 1
  },
  content: { display: "flex" },
  illustrationContainer: {},
  illustration: {
    verticalAlign: "middle",
    maxWidth: "30vw"
  },
  stepsContainer: {
    flex: 1,
    textAlign: "left",
    font: "400 20px/29px Roboto",
    letterSpacing: 0,
    color: "#000000",
    "& ol": {
      marginBlockStart: "1em",
      marginBlockEnd: "0em",
      opacity: 0.6
    },
    marginLeft: 10
  },
  helper: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle"
  },
  stepsHelper: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  "@media (max-width:960px)": {
    content: { flexDirection: "column" },
    illustration: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
      marginTop: "20px"
    }
  }
});

function Card1() {
  const classes = useStyles();
  return (
    <div variant="outlined" className={classes.root}>
      <div className={classes.content}>
        <div className={classes.stepsContainer}>
          <span className={classes.helper}></span>

          <div className={classes.stepsHelper}>
            <div className={classes.cardTitle}>
              Completa nuestros tres simples pasos y te encontraremos un auto.
            </div>
            <ol>
              <li>
                Elige tu auto: Elige todas la marca y modelo que te interesan.
              </li>
              <br />
              <li>Elige tu presupuesto: Presupuesto y tipo de pagos.</li>
              <br />

              <li>
                Informacion adicional: Dinos mas Acerca de ti, como
                contactarte, etc
              </li>
            </ol>
          </div>
        </div>
        <div className={classes.illustrationContainer}>
          <span className={classes.helper}></span>
          <img
            src="./assets/card/card1.svg"
            alt="Nuestros 3 pasos"
            className={classes.illustration}
          />
        </div>
      </div>
    </div>
  );
}

export default Card1;
