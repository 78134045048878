import { connect } from "react-redux";
import loginCheck from "store/actions/loginCheck";
import setProfile from "store/actions/setProfile";
import { getAll } from "store/actions/api";

import View from "./view";

function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // googleSignIn: dispatch(federatedSignIn("Google"))
    loginCheck: value => {
      dispatch(loginCheck(value));
    },
    login: value => {
      dispatch({ type: "SET_PROFILE", path: "loginChecked", value: false });
    },
    getAll() {
      dispatch(getAll());
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
