import React from "react";
import SetPasswordScreen from "./SetPasswordScreen";
import Subrouter from "router/Subrouter";
import { Switch } from "react-router-dom";

function Recover(props) {
  return (
    <>
      <Switch>
        {props.subroutes.map(function(route) {
          return Subrouter(route);
        })}
      </Switch>
    </>
  );
}

export default Recover;
