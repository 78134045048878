import { UPDATE_SEARCH, SET_SEARCH } from "store/const/actionTypes";
import { get, set, deepClone } from "utils";
const initState = {
  make: "",
  model: "",
  year: "",
  area: "",
  maxPrice: "",
  transmission: "",
  interiorColor: "",
  exteriorColor: "",
  kms: ""
};
export default function search(state = initState, action) {
  switch (action.type) {
    case UPDATE_SEARCH:
      const { path, value } = action;
      var nextState = deepClone(state);
      var res = set(nextState, path, value);
      return res ? nextState : state;
    case SET_SEARCH:
      const {
        make,
        model,
        year,
        area,
        maxPrice,
        transmission,
        interiorColor,
        exteriorColor,
        kms
      } = action.searchState;

      return {
        make,
        model,
        year,
        area,
        maxPrice,
        transmission,
        interiorColor,
        exteriorColor,
        kms
      };
    default:
      return state;
  }
}
