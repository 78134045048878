import { SET_PROFILE, UPDATE_PROFILE } from "../const/actionTypes";
import { Auth } from "aws-amplify";
import setProfile from "store/actions/setProfile";

export default function loginCheck(email, name) {
  return function(dispatch, getState) {
    const state = getState();
    if (state.profile.loginChecked) return;
    dispatch({ type: SET_PROFILE, path: "loginChecked", value: true });

    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        console.log(user);
        dispatch(setProfile(user.attributes.email, user.attributes.name));
      })
      .catch(e => {
        console.log(e);
      });
    // dispatch({ type: SET_PROFILE, path: "name", value: name });
  };
}
