import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;
  return {
    type: form.livingSituation.type,
    monthlyPayment: form.livingSituation.monthlyPayment,
    step: form.step
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 7 });
    },

    setType(value) {
      dispatch({ type: "UPDATE_FORM", path: "livingSituation.type", value });
    },
    setMonthlyPayment(value) {
      dispatch({
        type: "UPDATE_FORM",
        path: "livingSituation.monthlyPayment",
        value
      });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
