import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Card from "./Card";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  headerContainer: {},
  footerContainer: {
    bottom: 0,
    flexshrink: 0
  },

  recoverCard: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  content: {
    minHeight: "calc(100vh - 318px)",
    marginTop: 50,
    marginBottom: 100
  }
});

function Recover(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Header />

        <div className={classes.content}>
          <div className={classes.recoverCard}>
            <Card />
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Recover;
