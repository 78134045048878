import React, { useContext } from "react";
import Header from "components/Header";
import Footer from "components/Footer";

import { makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "./Card";

const useStyles = makeStyles({
  root: {
    // display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  headerContainer: {},
  footerContainer: {
    bottom: 0,
    flexshrink: 0
  },

  recoverCard: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  content: {
    minHeight: "calc(100vh - 318px)",
    marginTop: 50,
    marginBottom: 100
  }
});

function Step1(props) {
  const classes = useStyles();
  // console.log(appConstants);
  return (
    <>
      <div className={classes.root}>
        <Header />
        <div className={classes.content}>
          <Card />
        </div>
        <div className={classes.footerContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Step1;
