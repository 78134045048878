import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
// import Sedan from "assets/cars/sedan@2x.png";
const spring = {
  type: "spring",
  damping: 20,
  stiffness: 300
};
const useStyles = makeStyles({
  root: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // border: 0,
    // borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    // color: "white",
    // height: 48,
    // padding: "0 30px",
    width: "71%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 40,
    height: 700,
    boxSizing: "border-box",

    "@media (max-width:400px)":{
      height:770
    }
  },
  title: {
    textAlign: "center",
    font: "Bold 25px/33px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  rotArea: {
    width: "100%",
    height: 300,
    // backgroundColor: "green",
    paddingTop: 50,
    position: "relative",
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto"
  },
  car: {
    width: "60%",
    height: "auto",
    position: "absolute",
    // transition: "left 1s linear"
    "-webkit-transition": "opacity 1s ease-in-out",
    "-moz-transition": "opacity 1s ease-in-out",
    "-o-transition": "opacity 1s ease-in-out",
    transition: "opacity 1s ease-in-out"
  },
  centerCar: {
    bottom: 0,
    maxWidth: "500px",

    left: "50%",
    "-webkit-transform": "translateX(-50%)",
    transform: "translateX(-50%)",
    zIndex: 100
  },
  leftCar: {
    width: "30%",
    maxWidth: "315px",

    height: "auto",
    left: "5%",
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
    opacity: 0.6,
    filter: "blur(2px)"
  },
  rightCar: {
    width: "30%",
    maxWidth: "315px",

    height: "auto",
    right: "5%",
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
    opacity: 0.6,
    filter: "blur(2px)"
  },
  backCar: {
    width: "30%",
    maxWidth: "315px",

    top: "10%",

    left: "50%",
    "-webkit-transform": "translateX(-50%)",
    transform: "translateX(-50%)",
    zIndex: 97,
    opacity: 0.4,
    filter: "blur(2px)"
  },
  arrowLeft: {
    position: "absolute",
    left: "-60px",
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  arrowRight: {
    position: "absolute",
    right: "-60px",
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    transform: "translateY(-50%)"
  },
  ctaButton: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: 344,
    height: 56,
    font: "Bold 20px/29px Roboto",
    letterSpacing: 0,
    textTransform: "none",
    marginTop: 45,
    maxWidth: "80%"
  },
  supportText: {
    textAlign: "left",
    font: "Bold 20px/33px Roboto",
    letterSpacing: 0,
    color: "#000000",
    textAlign: "center",
    maxWidth: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px"
  }
});

const initialSelectorState = [
  { type: "automóvil ", src: "./assets/cars/auto.png", gender: "un" },
  { type: "minibus", src: "./assets/cars/minibus.png", gender: "un" },
  { type: "vagoneta", src: "./assets/cars/suv.png", gender: "una" },
  { type: "camioneta", src: "./assets/cars/pickup.png", gender: "una" }
];

const CENTER_CAR = 0,
  LEFT_CAR = 1,
  BACK_CAR = 2,
  RIGHT_CAR = 3;

function arrayRotate(arr, reverse) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr.concat();
}

function CarSelector(props) {
  const classes = useStyles();
  const [carOrder, setCarOrder] = useState(initialSelectorState);
  const { onSelection } = props;

  function selectionCallback(e) {
    onSelection(carOrder[0]);
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Simplifica tu búsqueda de automóviles y creditos
      </div>
      <div className={classes.rotArea}>
        {/* <img src="./assets/cars/sedan.png" className={classes.car} />
        <img src="./assets/cars/van.png" className={classes.car} />
        <img src="./assets/cars/sedan.png" className={classes.car} /> */}
        <IconButton
          className={classes.arrowLeft}
          onClick={() => {
            setCarOrder(arrayRotate(carOrder, true));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          className={classes.arrowRight}
          onClick={() => {
            setCarOrder(arrayRotate(carOrder));
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        <AnimatePresence>
          <motion.img
            src={carOrder[CENTER_CAR].src}
            className={clsx(classes.car, classes.centerCar)}
            alt="car1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.img
            src={carOrder[LEFT_CAR].src}
            className={clsx(classes.car, classes.leftCar)}
            alt="car2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />

          <motion.img
            src={carOrder[RIGHT_CAR].src}
            className={clsx(classes.car, classes.rightCar)}
            alt="car3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />

          <motion.img
            src={carOrder[BACK_CAR].src}
            className={clsx(classes.car, classes.backCar)}
            alt="car4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </AnimatePresence>
      </div>

      <Button
        color="secondary"
        variant="contained"
        className={classes.ctaButton}
        onClick={selectionCallback}
      >
        {`Quiero ${carOrder[0].gender} ${carOrder[CENTER_CAR].type}`}
      </Button>

      <div className={classes.supportText}>
        Recomendaciones de autos y creditos para tu presupuesto
      </div>

      {/* <Button color="secondary" variant="contained">
        rotate
      </Button> */}
    </div>
  );
}

export default CarSelector;
