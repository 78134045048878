import { connect } from "react-redux";
import { submitForm } from "store/actions/api";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;
  return {
    ...form.income,
    step: form.step
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 9 });
      return dispatch(submitForm());
    },

    updateForm(path, value) {
      dispatch({ type: "UPDATE_FORM", path, value });
    },
    setMonthlyIncome(value) {
      dispatch({ type: "UPDATE_FORM", path: "income.monthlyIncome", value });
    },
    setIncome3Months(value) {
      dispatch({ type: "UPDATE_FORM", path: "income.income3Months", value });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
