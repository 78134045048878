import { connect } from "react-redux";
import signOut from "store/actions/signOut";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    signOut() {
      return dispatch(signOut());
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
