import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Card,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { ReactComponent as GoogleIcon } from "assets/icons/Google-Button.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import EmailIcon from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  root: {
    width: 480,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: "80%",
    boxSizing: "border-box"
  },
  cardTitle: {
    font: "400 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20
  },
  googleButton: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    border: "1px solid #707070",
    borderRadius: "4px",
    height: 56,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#00000098"
  },
  facebookButton: {
    background: "#3B5998 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    borderRadius: "4px",
    height: 56,
    marginTop: 20,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#FFFFFF",
    "&:hover": {
      background: "#3B5998 0% 0% no-repeat padding-box"
    }
  },
  dividerEmail: {
    marginTop: 20,

    textAlign: "left",
    font: "400 16px/21px Roboto",
    letterSpacing: 0,
    color: "#000000",
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    width: "100%"
  },
  emailTextField: {
    marginTop: 20
  },
  emailIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  emailButton: {
    height: 56,
    marginTop: 20,
    textTransform: "none",

    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    }
  },

  actions: {
    marginTop: 20,
    textAlign: "left",
    font: "400 14px/19px Roboto",
    letterSpacing: 0,
    color: "#3886FC",
    "& a": {
      textDecoration: "none",
      color: "#3886FC"
    }
  }
});
function RegisterCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const [pwdVisible, setPwdVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const { googleSignIn, facebookSignIn, emailSignUp } = props;

  const [cpwdVisible, setCpwdVisible] = useState(false);
  return (
    <Card className={classes.root}>
      {" "}
      <span className={classes.cardTitle}>Registrate</span>
      <hr className={classes.separator} />
      <Button
        classes={{ root: classes.googleButton }}
        disableElevation
        onClick={googleSignIn}
      >
        <GoogleIcon />
        Registrate con Google
      </Button>
      <Button
        classes={{ root: classes.facebookButton }}
        disableElevation
        onClick={facebookSignIn}
      >
        <FacebookIcon style={{ marginRight: 5 }} />
        Registrate con Facebook
      </Button>
      <div className={classes.dividerEmail}>
        <span>O usa tu correo</span>
      </div>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Mi correo"
        className={classes.emailTextField}
        value={email}
        onChange={function(e) {
          setEmail(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.emailIcon}>
              <EmailIcon color="inherit" />
            </InputAdornment>
          )
        }}
      />
      <TextField
        variant="outlined"
        fullWidth
        type={pwdVisible ? "text" : "password"}
        placeholder="Contraseña"
        className={classes.emailTextField}
        value={pwd}
        onChange={function(e) {
          setPwd(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.emailIcon}>
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPwdVisible(!pwdVisible);
                  }}
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {pwdVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </InputAdornment>
          )
        }}
      />
      <TextField
        variant="outlined"
        fullWidth
        type={cpwdVisible ? "text" : "password"}
        placeholder="Confirma tu contraseña"
        className={classes.emailTextField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.emailIcon}>
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setCpwdVisible(!cpwdVisible);
                  }}
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {cpwdVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={classes.emailButton}
        onClick={function() {
          emailSignUp(email, pwd)
            .then(function(res) {
              history.push("/verificacion");
            })
            .catch(function() {
              history.push("/");
            });
        }}
        disableElevation
      >
        {" "}
        Registrate
      </Button>
      <div className={classes.actions}>
        <span>
          <a
            href="/recuperar"
            onClick={e => {
              e.preventDefault();
              history.push("/recuperar");
            }}
          >
            O Recupera tu contrasena
          </a>
        </span>
      </div>
    </Card>
  );
}

export default RegisterCard;
