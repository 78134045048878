/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getForm = `query GetForm($id: ID!) {
  getForm(id: $id) {
    id
    step
    type
    budget
    address {
      address1
      address2
      address3
    }
    personal {
      fullName
      email
      phone
    }
    birthdate
    livingSituation {
      type
      monthlyPayment
    }
    employment {
      employmentType
      companyName
      roleName
      isWorkingStudent
      otherDescription
    }
    income {
      monthlyIncome
      income3Months
    }
  }
}
`;
export const listForms = `query ListForms(
  $filter: ModelFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      step
      type
      budget
      address {
        address1
        address2
        address3
      }
      personal {
        fullName
        email
        phone
      }
      birthdate
      livingSituation {
        type
        monthlyPayment
      }
      employment {
        employmentType
        companyName
        roleName
        isWorkingStudent
        otherDescription
      }
      income {
        monthlyIncome
        income3Months
      }
    }
    nextToken
  }
}
`;
