import React from "react";
import { Switch, useHistory } from "react-router-dom";
import Subrouter from "router/Subrouter";

function Form(props) {
  console.log("FORM ROOT", props);
  const { step } = props;
  const history = useHistory();
  if (step === 9 && props.path !== "/form/gracias") {
    history.push("/form/gracias");
  }
  return (
    <Switch>
      {props.subroutes.map(function(route) {
        return Subrouter(route);
      })}
    </Switch>
  );
}

export default Form;
