import { connect } from "react-redux";
import { forgotPassword } from "store/actions/auth";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPassword(email) {
      return dispatch(forgotPassword(email));
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
