import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducers";
import loadState from "utils/loadState";
import saveState from "utils/saveState";

let devtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : null;
let store;
const persistedState = loadState();

if (devtools) {
  store = createStore(
    RootReducer,
    persistedState,
    compose(applyMiddleware(...[thunk]), devtools)
  );
} else {
  store = createStore(
    RootReducer,
    persistedState,
    compose(applyMiddleware(...[thunk]))
  );
}

store.subscribe(function() {
  saveState({ form: store.getState().form });
});
export default store;
