import { createContext } from "react";
const cars = [
  { type: "sedan", src: "/assets/cars/sedan.png", gender: "un" },
  { type: "van", src: "/assets/cars/van.png", gender: "una" },
  { type: "vagoneta", src: "/assets/cars/suv.png", gender: "una" },
  { type: "camioneta", src: "/assets/cars/pickup.png", gender: "una" }
];
const budgets = [
  { USD: 100, BOB: 700 },
  { USD: 200, BOB: 1400 },
  { USD: 300, BOB: 2100 }
];

const regions = [
  "La Paz",
  "Santa Cruz",
  "Cochabamba",
  "Tarija",
  "Chuquisaca",
  "Beni",
  "Pando",
  "Oruro",
  "Potosi"
];

const employmentTypes = [
  "Soy empleado/a de una empresa",
  "Tengo mi propia empresa",
  "Soy consultor/a independiente",
  "Soy estudiante",
  "Soy jubilado/a",
  "Otro"
];

const otherDescriptions = [
  "Discapacidad a largo plazo",
  "Manutencion conyugal",
  // "Child support",
  "Asistencia Social/Guvernamental",
  // "Descapacidad conyugal",
  "Actualmente no empleado/a"
];

const Api = {
  // googleSignIn() {
  //   alert("hello");
  //   Auth.federatedSignIn({ provider: "Google" });
  // },fdsafds
  // facebookSignin() {
  //   Auth.federatedSignIn({ provider: "Facebook" });
  // }
};

const constants = {
  cars,
  budgets,
  regions,
  employmentTypes,
  otherDescriptions,
  Api
};

const Constants = createContext(constants);

export default Constants;
