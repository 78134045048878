import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;

  return { form };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 4 });
    },

    updateForm(path, value) {
      dispatch({ type: "UPDATE_FORM", path, value });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
