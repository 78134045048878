/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://nlr6dxy5svd5vn552pjne3r73m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-abfhclhlxrghvpwfh4j5prguaq",
    "aws_content_delivery_bucket": "autofica-web-20200112220236-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://autofica-web-20200112220236-hostingbucket-prod.s3-website.us-east-2.amazonaws.com"
};


export default awsmobile;
