import React from "react";
import { setCookie, getCookie } from "../../cookie";
import Header from "components/Header";
import Footer from "components/Footer";
import LoginCard from "./LoginCard";

import { makeStyles } from "@material-ui/core";

// function View(props) {
//   return (
//     <div>
//       <button
//         onClick={() => {
//           setCookie("username", true, 10);
//         }}
//       >
//         Login
//       </button>
//     </div>
//   );
// }

const useStyles = makeStyles({
  root: {
    // display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  headerContainer: {},
  footerContainer: {
    // marginTop: "90px",
    // position: "absolute",
    // width: "100vw",
    bottom: 0,
    flexshrink: 0
  },

  loginCard: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  content: {
    minHeight: "calc(100vh - 318px)",
    marginTop: 50,
    marginBottom: 100
  }
});

function Login() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Header />

        <div className={classes.content}>
          <div className={classes.loginCard}>
            <LoginCard />
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Login;
