import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;
  return { step: form.step };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {};
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
