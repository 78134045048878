import { connect } from "react-redux";
import { confirmSignUp, setConfimed } from "store/actions/auth";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // googleSignIn: dispatch(federatedSignIn("Google"))

    confirmSignUp: (email, code) => {
      return dispatch(confirmSignUp(email, code));
    },
    setConfimed: (email, name) => {
      dispatch(setConfimed(email, name));
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
