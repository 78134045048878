/**
 *
 * @param {Object} object The object to get the value from
 * @param {string} path The path in the object to set the value
 * @param {Any} val The new value to be set in the object
 */
function setValue(object, path, val) {
  try {
    var stack = path.split(".");

    while (stack.length > 1) {
      object = object[stack.shift()];
    }

    object[stack.shift()] = val;
    return val;
  } catch (err) {
    return null;
  }
}

export default setValue;
