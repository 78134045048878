import React from "react";

import { setCookie, getCookie } from "../../cookie";
import { useHistory } from "react-router-dom";

function Auth() {
  const history = useHistory();
  return (
    <div>
      <button
        onClick={() => {
          setCookie("username", false, -1);
        }}
      >
        {" "}
        Logout{" "}
      </button>
      Auth
    </div>
  );
}

export default Auth;
