import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    width: "71%",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0.5px solid #696969",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: 30,
    textShadow: "none"
  },
  cardTitle: {
    textAlign: "left",
    font: "700 20px/33px Roboto",
    letterSpacing: 0,
    color: "#000000",
    position: "relative",
    opacity: 1,
    textShadow: "none"
  },
  content: { display: "flex" },
  illustrationContainer: {},
  illustration: {
    verticalAlign: "middle",
    maxWidth: "30vw"
  },
  stepsContainer: {
    flex: 1,
    textAlign: "left",
    font: "400 20px/29px Roboto",
    letterSpacing: 0,
    color: "#000000",
    "& ul": {
      marginBlockStart: "1em",
      marginBlockEnd: "0em",
      listStyleType: "none",
      opacity: 0.6,
      paddingInlineStart: 0
    },
    marginLeft: 10
  },
  helper: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle"
  },
  stepsHelper: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  "@media (max-width:960px)": {
    content: { flexDirection: "column" },
    illustration: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
      marginTop: "20px"
    }
  }
});

function Card2() {
  const classes = useStyles();
  return (
    <div variant="outlined" className={classes.root}>
      <div className={classes.content}>
        <Hidden smDown>
          <div className={classes.illustrationContainer}>
            <span className={classes.helper}></span>
            <img
              src="./assets/card/card2.svg"
              alt="Nuestros 3 pasos"
              className={classes.illustration}
            />
          </div>
        </Hidden>

        <div className={classes.stepsContainer}>
          <span className={classes.helper}></span>

          <div className={classes.stepsHelper}>
            <div className={classes.cardTitle}>
              Simplificamos el proceso de compra de autos
            </div>
            <ul>
              <li>
                <strong>Aplicacion segura –</strong> La transmicion de tus
                datos a travez de nuestro portal es segura.
              </li>
              <br />
              <li>
                <strong>Simplicidad –</strong> Nuestro sencillo formulario nos
                da los datos necesarios para ayudarte en tu búsqueda de autos y
                creditos.
              </li>
              <br />

              <li>
                <strong>Velocidad – </strong>En solo un par de minutos puedes
                llenar tu aplicacion y nuestro equipo comenzara tu busqueda de
                creditos y autos.
              </li>
            </ul>
          </div>
        </div>

        <Hidden mdUp>
          <div className={classes.illustrationContainer}>
            <span className={classes.helper}></span>
            <img
              src="./assets/card/card2.svg"
              alt="Nuestros 3 pasos"
              className={classes.illustration}
            />
          </div>
        </Hidden>
      </div>
    </div>
  );
}

export default Card2;
