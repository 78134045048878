import Amplify, { Auth } from "aws-amplify";
import awsconfig from "aws-exports";
import {
  SET_PROFILE,
  TRIGGER_AUTH,
  RESET_PROFILE
} from "store/const/actionTypes";
import { openSnack } from "./snack";
Amplify.configure(awsconfig);

export const federatedSignIn = function(provider) {
  return function(dispatch, getState) {
    dispatch({ type: TRIGGER_AUTH, value: provider });
    return Auth.federatedSignIn({ provider: provider })
      .then(cred => {
        // If success, you will get the AWS credentials
        console.log(cred);
        return Auth.currentAuthenticatedUser();
      })
      .then(user => {
        // If success, the user object you passed in Auth.federatedSignIn
        console.log(user);
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export function setUnconfirmed(email, name) {
  return function(dispatch, getState) {
    dispatch({ type: SET_PROFILE, path: "email", value: email });
    dispatch({ type: SET_PROFILE, path: "fullName", value: name });
    dispatch({ type: SET_PROFILE, path: "loggedIn", value: true });
    dispatch({ type: SET_PROFILE, path: "confirmed", value: false });
  };
}

export function setConfimed(email, name) {
  return function(dispatch, getState) {
    dispatch({ type: SET_PROFILE, path: "email", value: email });
    dispatch({ type: SET_PROFILE, path: "fullName", value: name });
    dispatch({ type: SET_PROFILE, path: "loggedIn", value: true });
    dispatch({ type: SET_PROFILE, path: "confirmed", value: true });
  };
}

export const signIn = function(username, password) {
  return function(dispatch, getState) {
    dispatch({ type: TRIGGER_AUTH, value: { username } });
    return new Promise(function(resolve, reject) {
      SignIn3(username, password, dispatch)
        .then(res => {
          if (res) {
            setConfimed(username, "");
            return resolve();
          }
          reject();
        })
        .catch(function(err) {
          console.log(err);
          reject();
        });
    });
  };
};

export const confirmSignUp = function(username, code) {
  return function(dispatch, getState) {
    dispatch({ type: TRIGGER_AUTH, value: { username } });
    return Auth.confirmSignUp(username, code)
      .then(function(res) {
        dispatch({ type: SET_PROFILE, path: "email", value: username });
        dispatch({ type: SET_PROFILE, path: "fullName", value: "" });
        dispatch({ type: SET_PROFILE, path: "loggedIn", value: true });
        dispatch({ type: SET_PROFILE, path: "confirmed", value: true });

        const action = openSnack("Success", "Verificacion completa");
        dispatch(action);
      })
      .catch(function(err) {
        const action = openSnack(
          "warning",
          "Porfavor verifica que el codigo de verificacion sea correcto."
        );
        dispatch(action);
        throw err;
      });
  };
};

export const signUp = function(username, password) {
  return function(dispatch, getState) {
    dispatch({ type: TRIGGER_AUTH, value: { username } });

    return new Promise(function(resolve, reject) {
      Auth.signUp({ username, password, attributes: { name: "" } })
        .then(res => {
          console.log(res);
          dispatch(setConfimed(username));

          resolve(res);
        })
        .catch(function(err) {
          let switcher = err.code || err.name;
          switch (switcher) {
            case "UsernameExistsException": {
              const action = openSnack(
                "warning",
                "Ya existe una cuenta registrada con este correo. Ingresa a tu cuenta o recupera tu contrasena"
              );
              dispatch(action);
              return;
            }
            case "AuthError":
            case "InvalidParameterException": {
              const action = openSnack(
                "warning",
                "Porfavor ingresa un correo electronico valido y una contrasena con al menos 8 caracteres"
              );
              dispatch(action);
            }
            default:
          }
          reject(err);
          console.log(err);
        });
    });
  };
};

export const forgotPassword = function(username) {
  return function(dispatch, getState) {
    return new Promise(function(res, rej) {
      Auth.forgotPassword(username)
        .then(data => {
          dispatch({ type: SET_PROFILE, path: "email", value: username });
          dispatch({ type: SET_PROFILE, path: "forgotPassword", value: true });
          res(data);
        })
        .catch(err => rej(err));
    });
  };
};

export const changePassword = function(username, vCode, newPassword) {
  return function(dispatch, getState) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, vCode, newPassword)
        .then(data => {
          dispatch({ type: RESET_PROFILE });
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

async function SignIn3(username, password, dispatch) {
  try {
    const user = await Auth.signIn(username, password);
    if (
      user.challengeName === "SMS_MFA" ||
      user.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      // // You need to get the code from the UI inputs
      // // and then trigger the following function with a button click
      // const code = getCodeFromUserInput();
      // // If MFA is enabled, sign-in should be confirmed with the confirmation code
      // const loggedUser = await Auth.confirmSignIn(
      //   user, // Return object from Auth.signIn()
      //   code, // Confirmation code
      //   mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      // );
    } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      // // You need to get the new password and required attributes from the UI inputs
      // // and then trigger the following function with a button click
      // // For example, the email and phone_number are required attributes
      // const { username, email, phone_number } = getInfoFromUserInput();
      // const loggedUser = await Auth.completeNewPassword(
      //   user, // the Cognito User Object
      //   newPassword, // the new password
      //   // OPTIONAL, the required attributes
      //   {
      //     email,
      //     phone_number
      //   }
      // );
      console.log("NEW_PASSWORD_REQUIRED");
    } else if (user.challengeName === "MFA_SETUP") {
      // // This happens when the MFA method is TOTP
      // // The user needs to setup the TOTP before using it
      // // More info please check the Enabling MFA part
      // Auth.setupTOTP(user);
    } else {
      // // The user directly signs in
      // console.log(user);
      return true;
    }
  } catch (err) {
    if (err.code === "UserNotConfirmedException") {
      dispatch(setUnconfirmed(username, ""));
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === "PasswordResetRequiredException") {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === "NotAuthorizedException") {
      const action = openSnack("warning", "Contrasena incorrecta");
      dispatch(action);

      // The error happens when the incorrect password is provided
    } else if (err.code === "UserNotFoundException") {
      const action = openSnack(
        "warning",
        "No existe un usuario con este nombre"
      );
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      dispatch(action);
    } else {
      let action;
      switch (err.name) {
        case "InvalidParameterException":
        case "AuthError":
          action = openSnack(
            "warning",
            "Asegurese de proveer un correo electronico valido y su contrasena"
          );
          break;
        default:
          action = openSnack(
            "warning",
            "Hubo un error Porfavor intente ingresar de nuevo."
          );
          break;
      }

      dispatch(action);

      console.log(err);
    }
    console.log(err);

    return false;
  }
}
// For advanced usage
// You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger
// Auth.signIn({
//     username, // Required, the username
//     password, // Optional, the password
//     validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
// }).then(user => console.log(user))
// .catch(err => console.log(err));
