import { SET_PROFILE, RESET_PROFILE } from "../const/actionTypes";
import { Auth } from "aws-amplify";
import setProfile from "store/actions/setProfile";

export default function signOut() {
  return function(dispatch, getState) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(data => {
          dispatch({ type: RESET_PROFILE });

          console.log(data);

          resolve(true);
        })
        .catch(err => {
          dispatch({ type: RESET_PROFILE });
          reject(err);
          console.log(err);
        });
    });
    // dispatch({ type: SET_PROFILE, path: "name", value: name });
  };
}

// function signOut() {
//     Auth.signOut()
//     .then(data => console.log(data))
//     .catch(err => console.log(err));

// }
