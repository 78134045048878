import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

function delete_null_properties(test, recurse) {
  for (var i in test) {
    if (test[i] === null || test[i] === "") {
      delete test[i];
    } else if (recurse && typeof test[i] === "object") {
      delete_null_properties(test[i], recurse);
    }
  }
}

export const submitForm = function (formData) {
  return function (dispatch, getState) {
    const state = getState();
    let { form } = state;
    // return API.graphql({
    //   query: mutations.createForm,
    //   variables: { input: formData },
    //   authMode: "AWS_IAM"
    // });
    console.log(form);

    delete_null_properties(form, true);
    return API.graphql(graphqlOperation(mutations.createForm, { input: form }));
  };
};

export const getAll = function () {
  return function (dispatch, getState) {
    const state = getState();
    const { form } = state;
    return API.graphql({
      query: queries.listForms,
      variables: { limit: 10 },
      authMode: "AWS_IAM",
    })
      .then((data) => {
        console.log("ALL", data);
      })
      .catch((data) => {
        console.log("ERR ALL", data);
      });
  };
};
