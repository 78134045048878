import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Subrouter from "./Subrouter";

const Router = ({ routes }) => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(function(route) {
          return Subrouter(route);
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
