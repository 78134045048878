import { connect } from "react-redux";
import { changePassword } from "store/actions/auth";
import { openSnack } from "store/actions/snack";
import View from "./view";
function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch) {
  return {
    setNewPassword(uname, code, pwd) {
      return dispatch(changePassword(uname, code, pwd));
    },
    displaySuccessMessage(message) {
      dispatch(openSnack("success", "Contraseña cambiada exitosamente"));
    },
    displayErrorMessage(message) {
      dispatch(
        openSnack(
          "error",
          "Porfavor asegurate que tu contraseña tenga almenos 8 caracteres y que tu codigo sea valido"
        )
      );
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
