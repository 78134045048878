import React from "react";
import { makeStyles, Hidden, TextField, Card, Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "71%",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0.5px solid #696969",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: 30
  },
  cardTitle: {
    textAlign: "left",
    font: "700 20px/33px Roboto",
    letterSpacing: 0,
    color: "#000000",
    position: "relative",
    opacity: 1,
    textShadow: "none"
  },
  content: { display: "flex" },
  illustrationContainer: {},
  illustration: {
    verticalAlign: "middle",
    maxWidth: "30vw"
  },
  stepsContainer: {
    flex: 1,
    textAlign: "left",
    font: "400 20px/29px Roboto",
    letterSpacing: 0,
    color: "#000000",

    marginLeft: 10
  },
  helper: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle"
  },
  stepsHelper: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  subBtn: {
    color: "white",
    opacity: "1 !important",
    height: "56px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: "30%"
  },
  info: {
    marginBlockStart: "1em",
    marginBlockEnd: "0em",
    listStyleType: "none",
    opacity: 0.6,
    paddingInlineStart: 0
  },
  emailInput: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: "70%"
  }
});

function Card3() {
  const classes = useStyles();
  return (
    <div variant="outlined" className={classes.root}>
      <div className={classes.content}>
        <div className={classes.stepsContainer}>
          <span className={classes.helper}></span>

          <div className={classes.stepsHelper}>
            <div className={classes.cardTitle}>
              Suscríbete a nuestra lista de correos
            </div>
            <span className={classes.info}>
              Noticias de autofica y nuevas ofertas de productos
            </span>
            <br />
            <br />
            <div>
              <TextField
                variant="outlined"
                placeholder="Mi correo"
                className={classes.emailInput}
                classes={{ root: classes.emailInput }}
              />
              <Button
                color="secondary"
                variant="contained"
                className={classes.subBtn}
                disableElevation
              >
                Suscribete
              </Button>
            </div>
          </div>
        </div>

        <Hidden smDown>
          <div className={classes.illustrationContainer}>
            <span className={classes.helper}></span>
            <img
              src="./assets/card/card3.svg"
              alt="Nuestros 3 pasos"
              className={classes.illustration}
            />
          </div>
        </Hidden>
      </div>
    </div>
  );
}

export default Card3;
