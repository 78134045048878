import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Card, Button, Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "100%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: {},
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  }
}));

function RecoverCard(props) {
  const { updateForm, form, nextStep } = props;
  const { budget } = form;

  const classes = useStyles();
  const history = useHistory();
  const appConstants = useContext(Constants);
  const [selected, setSelected] = useState(budget);
  const { budgets, cars } = appConstants;

  console.log(selected);

  if (form.step < 2) {
    history.push(`./${form.step}`);
  }

  if (form.step === 9) {
    history.push(`/form/gracias`);
  }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={30}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>Elige tu presupuesto</span>
        <hr className={classes.separator} />
        <div className={classes.fill}></div>
        <div className={classes.questions}>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={6} alignItems={"center"}>
              <Button
                variant="outlined"
                fullWidth
                placeholder="Mi correo"
                className={
                  classes.button +
                  (selected === `Menos de ${budgets[0].USD} USD`
                    ? " " + classes.buttonSelected
                    : "")
                }
                onClick={function(e) {
                  setSelected(
                    selected === `Menos de ${budgets[0].USD} USD`
                      ? ""
                      : `Menos de ${budgets[0].USD} USD`
                  );
                }}
              >
                <span> {`Menos de ${budgets[0].USD} USD`}</span>
              </Button>
            </Grid>
            <Grid item xs={12} md={6} alignItems={"center"}>
              <Button
                variant="outlined"
                fullWidth
                placeholder="Mi correo"
                className={
                  classes.button +
                  (selected === `${budgets[0].USD} - ${budgets[1].USD} USD`
                    ? " " + classes.buttonSelected
                    : "")
                }
                onClick={function(e) {
                  setSelected(
                    selected === `${budgets[0].USD} - ${budgets[1].USD} US`
                      ? ""
                      : `${budgets[0].USD} - ${budgets[1].USD} USD`
                  );
                }}
              >
                <span> {`${budgets[0].USD} - ${budgets[1].USD} USD`}</span>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} alignItems={"center"}>
              <Button
                variant="outlined"
                fullWidth
                placeholder="Mi correo"
                className={
                  classes.button +
                  (selected === `${budgets[1].USD} - ${budgets[2].USD} USD`
                    ? " " + classes.buttonSelected
                    : "")
                }
                onClick={function(e) {
                  setSelected(
                    selected === `${budgets[1].USD} - ${budgets[2].USD} US`
                      ? ""
                      : `${budgets[1].USD} - ${budgets[2].USD} USD`
                  );
                }}
              >
                <span> {`${budgets[1].USD} - ${budgets[2].USD} USD`}</span>
              </Button>
            </Grid>

            <Grid item xs={12} md={6} alignItems={"center"}>
              <Button
                variant="outlined"
                fullWidth
                placeholder="Mi correo"
                className={
                  classes.button +
                  (selected === `Mas de ${budgets[2].USD} USD`
                    ? " " + classes.buttonSelected
                    : "")
                }
                onClick={function(e) {
                  setSelected(
                    selected === `Mas de ${budgets[2].USD} USD`
                      ? ""
                      : `Mas de ${budgets[2].USD} USD`
                  );
                }}
              >
                <span> {`Mas de ${budgets[2].USD} USD`}</span>
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.fill}></div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            disableElevation
            onClick={function() {
              updateForm("budget", selected);
              nextStep();
              history.push("./3");
            }}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </>
  );
}

export default RecoverCard;
