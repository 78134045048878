import React, { useEffect } from "react";
import Router from "router";
import routes from "router/routes";
import { Hub } from "aws-amplify";
import Snack from "components/Snack";

function App(props) {
  const { loginCheck, login } = props;
  useEffect(() => {
    loginCheck();
    Hub.listen("auth", data => {
      const { payload } = data;
      if (payload.event === "signIn") login();
    });
  });

  props.getAll();
  return (
    <>
      <Router routes={routes} />
      <Snack />
    </>
  );
}

export default App;
