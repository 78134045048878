import { connect } from "react-redux";
import { closeSnack } from "store/actions/snack";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return {
    open: state.snack.open,
    message: state.snack.message,
    snackType: state.snack.snackType
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleClose() {
      dispatch(closeSnack());
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
