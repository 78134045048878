import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Card,
  Button,
  TextField,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { ReactComponent as GoogleIcon } from "assets/icons/Google-Button.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import EmailIcon from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  root: {
    width: 480,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: "80%",
    boxSizing: "border-box"
  },
  cardTitle: {
    font: "400 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20
  },
  googleButton: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    border: "1px solid #707070",
    borderRadius: "4px",
    height: 56,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#00000098"
  },
  facebookButton: {
    background: "#3B5998 0% 0% no-repeat padding-box",
    boxShadow: "0px 1px 3px #00000033",
    borderRadius: "4px",
    height: 56,
    marginTop: 20,
    width: "100%",
    textTransform: "none",
    font: "Bold 16px/29px Roboto",
    color: "#FFFFFF",
    "&:hover": {
      background: "#3B5998 0% 0% no-repeat padding-box"
    }
  },
  dividerEmail: {
    marginTop: 20,

    // textAlign: "left",
    font: "400 16px/21px Roboto",
    letterSpacing: 0,
    color: "#000000",
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    width: "100%"
  },
  emailTextField: {
    marginTop: 20
  },
  emailIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  emailButton: {
    height: 56,
    marginTop: 20,
    textTransform: "none",

    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    }
  }
});
function RecoverCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { forgotPassword } = props;
  const [email, setEmail] = useState("");
  const [clicked, setClicked] = useState(false);
  return (
    <Card className={classes.root}>
      {" "}
      <span className={classes.cardTitle}>Recupera mi contraseña</span>
      <hr className={classes.separator} />
      <div className={classes.dividerEmail}>
        <span>Escribe tu correo electronico para recuperar tu contraseña</span>
      </div>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Mi correo"
        value={email}
        className={classes.emailTextField}
        onChange={function(e) {
          setEmail(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.emailIcon}>
              <EmailIcon color="inherit" />
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={classes.emailButton}
        disabled={clicked}
        disableElevation
        onClick={function(e) {
          setClicked(true);
          forgotPassword(email)
            .then(function(res) {
              console.log(res);
              history.push("/recuperar/cambio");
              setClicked(false);
            })
            .catch(function(err) {
              console.log(err);
              setClicked(false);

              // TODO handle error
            });
        }}
      >
        {" "}
        {clicked ? <CircularProgress /> : "Recuperar mi contraseña"}
      </Button>
    </Card>
  );
}

export default RecoverCard;
