import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import Icon from "@mdi/react";
import IconButton from "@material-ui/core/IconButton";

import { mdiInstagram, mdiTwitter, mdiFacebook } from "@mdi/js";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: "left",
    font: "Regular 10px/16px Raleway",
    letterSpacing: 0
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    height: 250,
    marginBottom: -250,
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    boxSizing: "border-box",
    paddingTop: 60,
    paddingBottom: 20,
    display: "flex",
    font: "Regular 10px/16px Raleway",
    lineHeight: 1.5
  },
  about: {
    width: "10%",
    minWidth: 250,
    marginRight: 20
  },
  contact: {
    width: "10%",
    minWidth: 250
  },
  fill: {
    flex: 1
  }
}));
function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <Hidden smDown>
          <div className={classes.about}>
            <span>
              <strong>Acerca de Nosotros</strong>
              <br />
              <br />
              <span>
                Autofica es un servicio web que une potenciales compradores de
                vehiculos con concesionarios y autoventas
              </span>
            </span>
          </div>
        </Hidden>

        <div className={classes.contact}>
          <span>
            <strong>Contacto</strong>
            <br />
            <br />
            <span>
              Emporio Centro, Murillo 929, <br />
              La Paz Zona Central
            </span>
          </span>
        </div>
        <div className={classes.fill}></div>
        <div className={classes.social}>
          <IconButton>
            <Icon
              path={mdiInstagram}
              title="Instagram"
              size={1}
              color="white"
            />
          </IconButton>
          <IconButton>
            <Icon path={mdiTwitter} title="Twitter" size={1} color="white" />
          </IconButton>
          <IconButton>
            <Icon path={mdiFacebook} title="Facebook" size={1} color="white" />
          </IconButton>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
