import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#7bc0ff",
      main: "#3890fc",
      dark: "#0063c8",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff7290",
      main: "#fc3863",
      dark: "#c20039",
      contrastText: "#fff"
    },
    success: {
      main: "#4caf50"
    },
    info: {
      main: "#2196f3"
    },
    warning: {
      main: "#ff9800"
    }
  }
});

export default theme;
