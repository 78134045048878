import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Card,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "70%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: {},
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  },
  label: {
    textAlign: "left",
    font: "500 16px/29px Roboto",
    letterSpacing: 0,
    color: "#000000"
  }
}));

function RecoverCard(props) {
  const { form, updateForm, nextStep } = props;
  const classes = useStyles();
  const history = useHistory();
  const appConstants = useContext(Constants);
  // const [addr1, setAddr1] = useState(form.address.address1 || " ");
  // const [addr2, setAddr2] = useState(form.address.address2);
  // const [addr3, setAddr3] = useState(form.address.address3);
  const addr1 = form.address.address1;
  function setAddr1(val) {
    updateForm("address.address1", val);
  }

  const addr2 = form.address.address2;
  function setAddr2(val) {
    console.log(val);
    updateForm("address.address2", val);
  }

  const addr3 = form.address.address3;
  function setAddr3(val) {
    updateForm("address.address3", val);
  }

  const { regions } = appConstants;

  // console.log(selected);

  if (form.step < 3) {
    history.push(`./${form.step}`);
  }

  if (form.step === 9) {
    history.push(`/form/gracias`);
  }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={40}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>Informacion de contacto</span>
        <hr className={classes.separator} />
        <div className={classes.fill}></div>
        <div className={classes.questions}>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>Departamento</span>
            </Grid>
            <Grid item xs={12} md={9} alignItems={"center"}>
              <Select
                fullWidth
                variant="outlined"
                InputProps={{ placeholder: "Elige tu departamento" }}
                value={addr1}
                onChange={function(e) {
                  setAddr1(e.target.value);
                }}
              >
                <MenuItem value={" "} disabled>
                  Elige tu departamento
                </MenuItem>
                {regions.map(function(item) {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>Zona</span>
            </Grid>

            <Grid item xs={12} md={9} alignItems={"center"}>
              <TextField
                fullWidth
                variant="outlined"
                value={addr2}
                onChange={function(e) {
                  setAddr2(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>Calle y Numero</span>
            </Grid>

            <Grid item xs={12} md={9} alignItems={"center"}>
              <TextField
                fullWidth
                variant="outlined"
                value={addr3}
                onChange={function(e) {
                  setAddr3(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.fill}></div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            disableElevation
            onClick={function() {
              nextStep();
              history.push("./4");
            }}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </>
  );
}

export default RecoverCard;
