import React, { useEffect } from "react";
import Header from "components/Header";
import CarSelector from "components/CarSelector";
import { Card1, Card2, Card3 } from "./Card";
import Footer from "components/Footer";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  cardMargin: {
    marginTop: 20
  },
  footerContaier: {
    marginTop: 90
  }
});

function Home(props) {
  const classes = useStyles();
  const { selectCar, step, resetForm } = props;
  const history = useHistory();

  useEffect(function() {
    if (step === 9) {
      resetForm();
    }
  });
  function selectionCallback(selection) {
    if (step < 9) {
      const res = Math.max(2, step);
      selectCar(selection, res);
      history.push(`/form/${res}`);
    } else {
      history.push(`/form/gracias`);
    }
  }

  return (
    <>
      <Header />
      <CarSelector onSelection={selectionCallback} />
      <Card1 />
      <div className={classes.cardMargin}>
        <Card2 />
      </div>
      <div className={classes.cardMargin}>
        <Card3 />
      </div>
      <div className={classes.footerContaier}>
        <Footer />
      </div>
    </>
  );
}

export default Home;
