// User related
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const RESET_PROFILE = "RESET_PROFILE";
// Applicant info
export const UPDATE_FORM = "UPDATE_FORM";
export const RESET_FORM = "RESET_FORM";
// Search functionality
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const SET_SEARCH = "UPDATE_SEARCH";
// Change cars
export const UPDATE_GARAGE = "UPDATE_GARAGE";

//Auth action types
export const TRIGGER_AUTH = "TRIGGER_AUTH";
export const CHECK_AUTH = "CHECK_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";

//Snackbar
export const CLOSE_SNACK = "CLOSE_SNACK";
export const OPEN_SNACK = "OPEN_SNACK";
