import theme from "theme";
import Home from "screens/Home";
import Auth from "screens/Auth";
import Search from "screens/Search";
import NotFound from "screens/NotFound";
import Login from "screens/Login";
import Register from "screens/Register";
import Recover from "screens/Recover";
import SetPasswordScreen from "screens/Recover/SetPasswordScreen";
import RecoverScreen from "screens/Recover/RecoverScreen";
import Form from "screens/Form";
import Step1 from "screens/Form/Step1";
import Step2 from "screens/Form/Step2";
import Step3 from "screens/Form/Step3";
import Step4 from "screens/Form/Step4";
import Step5 from "screens/Form/Step5";
import Step6 from "screens/Form/Step6";
import Step7 from "screens/Form/Step7";
import Step8 from "screens/Form/Step8";
import ThankYou from "screens/Form/ThankYou";
import Verification from "screens/Verification";
const routes = [
  { path: "/auth", type: "private", exact: true, component: Auth },
  {
    path: "/search",
    type: "dual",
    exact: true,
    public: Search,
    private: () => {
      return "private search";
    }
  },
  // { path: "/search", type: "public", exact: true, component: Search },
  {
    path: "/form",
    type: "public",
    component: Form,
    subroutes: [
      {
        path: "/form/1",
        type: "public",
        exact: true,
        component: Step1
      },
      {
        path: "/form/2",
        type: "public",
        exact: true,
        component: Step2
      },
      {
        path: "/form/3",
        type: "public",
        exact: true,
        component: Step3
      },
      {
        path: "/form/4",
        type: "public",
        exact: true,
        component: Step4
      },
      {
        path: "/form/5",
        type: "public",
        exact: true,
        component: Step5
      },
      {
        path: "/form/6",
        type: "public",
        exact: true,
        component: Step6
      },

      {
        path: "/form/7",
        type: "public",
        exact: true,
        component: Step7
      },
      {
        path: "/form/8",
        type: "public",
        exact: true,
        component: Step8
      },

      {
        path: "/form/gracias",
        type: "public",
        exact: true,
        component: ThankYou
      },
      {
        redirect: "/form/gracias",
        type: "dual",
        public: NotFound,
        private: NotFound
      }
    ]
  },

  {
    path: "/ingresar",
    type: "public",
    exact: true,
    component: Login
  },
  { path: "/registrar", type: "public", exact: true, component: Register },
  {
    path: "/recuperar",
    type: "public",
    component: Recover,
    subroutes: [
      {
        path: "/recuperar",
        type: "public",
        exact: true,
        component: RecoverScreen
      },
      {
        path: "/recuperar/cambio",
        type: "public",
        exact: true,
        component: SetPasswordScreen
      },
      { type: "dual", public: NotFound, private: NotFound }
    ]
  },
  {
    path: "/verificacion",
    type: "public",
    exact: true,
    component: Verification
  },

  {
    path: "/gracias",
    type: "public",
    exact: true,
    component: ThankYou
  },
  { path: "/", type: "dual", exact: true, public: Home, private: Home },
  { type: "dual", public: NotFound, private: NotFound }
];

export default routes;
