import React, { useContext, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Card,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "70%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: {},
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  },
  label: {
    textAlign: "left",
    font: "500 16px/29px Roboto",
    letterSpacing: 0,
    color: "#000000"
  }
}));

var initState = {
  employmentType: "",
  companyName: "",
  roleName: "",
  isWorkingStudent: "",
  otherDescription: ""
};

function cardReducer(state, action) {
  switch (action.type) {
    case "SET_EMP_TYPE": {
      var temp = { ...initState };
      temp.employmentType = action.value;
      return temp;
    }
    case "SET_EMP_NAME": {
      var temp = { ...state };
      temp.companyName = action.value;
      return temp;
    }
    case "SET_EMP_ROLE": {
      var temp = { ...state };
      temp.roleName = action.value;
      return temp;
    }

    case "SET_EMP_WS": {
      var temp = { ...state };
      temp.isWorkingStudent = action.value;
      return temp;
    }

    case "SET_OTHER_DESC": {
      var temp = { ...state };
      temp.otherDescription = action.value;
      return temp;
    }
    default:
      return state;
  }
  return state;
}

function StepCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const appConstants = useContext(Constants);
  const { employmentTypes, otherDescriptions } = appConstants;
  const { state, dispatch, nextStep, step } = props;
  const {
    employmentType,
    companyName,
    roleName,
    isWorkingStudent,
    otherDescription
  } = state;

  if (step < 7) {
    history.push(`./${step}`);
  }

  if (step === 9) {
    history.push(`/form/gracias`);
  }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={80}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>Informacion de empleo</span>
        <hr className={classes.separator} />
        <div className={classes.fill}></div>
        <div className={classes.questions}>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>Tipo de Empleo</span>
            </Grid>
            <Grid item xs={12} md={9} alignItems={"center"}>
              <Select
                fullWidth
                variant="outlined"
                value={employmentType}
                onChange={function(e) {
                  dispatch({ type: "SET_EMP_TYPE", value: e.target.value });
                  // setEmploymentType(e.target.value);
                }}
              >
                <MenuItem value={" "} disabled>
                  Elige tu tipo de empleo
                </MenuItem>
                {employmentTypes.map(function(item) {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>

          {employmentType === "Soy estudiante" ? (
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={3} alignItems={"center"}>
                <span className={classes.label}>
                  Trabajas mientras estudias?
                </span>
              </Grid>
              <Grid item xs={12} md={9} alignItems={"center"}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={isWorkingStudent}
                    onChange={function(e) {
                      dispatch({ type: "SET_EMP_WS", value: e.target.value });
                    }}
                    className={classes.radioRoot}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {employmentType === "Soy empleado/a de una empresa" ||
          employmentType === "Tengo mi propia empresa" ||
          isWorkingStudent === "Si" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} alignItems={"center"}>
                <span className={classes.label}>Nombre de la empresa</span>
              </Grid>

              <Grid item xs={12} md={9} alignItems={"center"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={
                    employmentType !== "Tengo mi propia empresa"
                      ? "Nombre de la empresa"
                      : "Nombre de tu empresa"
                  }
                  value={companyName}
                  onChange={function(e) {
                    dispatch({ type: "SET_EMP_NAME", value: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {employmentType === "Soy empleado/a de una empresa" ||
          employmentType === "Tengo mi propia empresa" ||
          employmentType === "Soy consultor/a independiente" ||
          isWorkingStudent === "Si" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} alignItems={"center"}>
                <span className={classes.label}>Nombre de tu cargo</span>
              </Grid>

              <Grid item xs={12} md={9} alignItems={"center"}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="El nombre de tu cargo"
                  value={roleName}
                  onChange={function(e) {
                    dispatch({ type: "SET_EMP_ROLE", value: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {employmentType === "Otro" ? (
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={3} alignItems={"center"}>
                <span className={classes.label}>
                  Elige la opcion que mejor te describe
                </span>
              </Grid>
              <Grid item xs={12} md={9} alignItems={"center"}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={otherDescription}
                  onChange={function(e) {
                    dispatch({ type: "SET_OTHER_DESC", value: e.target.value });
                    // setEmploymentType(e.target.value);
                  }}
                >
                  <MenuItem value={" "} disabled>
                    Elige tu fuente de ingresos
                  </MenuItem>
                  {otherDescriptions.map(function(item) {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </div>
        <div className={classes.fill}></div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            disableElevation
            onClick={function() {
              nextStep();
              history.push("./8");
            }}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </>
  );
}

export default StepCard;
