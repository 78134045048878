import { connect } from "react-redux";
import View from "./view";
import { UPDATE_FORM, RESET_FORM } from "store/const/actionTypes";
function mapStateToProps(state, ownProps) {
  //   console.log(state);
  const { form } = state;
  return { step: form.step };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectCar({ type }, step) {
      console.log("SELECT CAR", type, step);
      dispatch({ type: UPDATE_FORM, path: "type", value: type });
      dispatch({ type: UPDATE_FORM, path: "step", value: step });
    },
    resetForm() {
      dispatch({ type: RESET_FORM });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
