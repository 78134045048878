/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createForm = `mutation CreateForm(
  $input: CreateFormInput!
  $condition: ModelFormConditionInput
) {
  createForm(input: $input, condition: $condition) {
    id
    step
    type
    budget
    address {
      address1
      address2
      address3
    }
    personal {
      fullName
      email
      phone
    }
    birthdate
    livingSituation {
      type
      monthlyPayment
    }
    employment {
      employmentType
      companyName
      roleName
      isWorkingStudent
      otherDescription
    }
    income {
      monthlyIncome
      income3Months
    }
  }
}
`;
export const updateForm = `mutation UpdateForm(
  $input: UpdateFormInput!
  $condition: ModelFormConditionInput
) {
  updateForm(input: $input, condition: $condition) {
    id
    step
    type
    budget
    address {
      address1
      address2
      address3
    }
    personal {
      fullName
      email
      phone
    }
    birthdate
    livingSituation {
      type
      monthlyPayment
    }
    employment {
      employmentType
      companyName
      roleName
      isWorkingStudent
      otherDescription
    }
    income {
      monthlyIncome
      income3Months
    }
  }
}
`;
export const deleteForm = `mutation DeleteForm(
  $input: DeleteFormInput!
  $condition: ModelFormConditionInput
) {
  deleteForm(input: $input, condition: $condition) {
    id
    step
    type
    budget
    address {
      address1
      address2
      address3
    }
    personal {
      fullName
      email
      phone
    }
    birthdate
    livingSituation {
      type
      monthlyPayment
    }
    employment {
      employmentType
      companyName
      roleName
      isWorkingStudent
      otherDescription
    }
    income {
      monthlyIncome
      income3Months
    }
  }
}
`;
