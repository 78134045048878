import { SET_PROFILE, UPDATE_PROFILE } from "../const/actionTypes";
export default function setProfile(email, name) {
  return function(dispatch, getState) {
    dispatch({ type: SET_PROFILE, path: "email", value: email });
    dispatch({ type: SET_PROFILE, path: "fullName", value: name });
    dispatch({ type: SET_PROFILE, path: "loggedIn", value: true });
    dispatch({ type: SET_PROFILE, path: "confirmed", value: true });
    dispatch({ type: SET_PROFILE, path: "forgotPassword", value: false });
  };
}
