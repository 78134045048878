import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { useHistory } from "react-router-dom";

// Third Party
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Hidden } from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import flag from "../../assets/flags/Bolivia.png"

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

const navLinks = [
  // {
  //   label: "Vende",
  //   path: "/vende"
  // },
  // {
  //   label: "Contacto",
  //   path: "/contacto"
  // },
  // {
  //   label: "Ayuda",
  //   path: "/ayuda"
  // },
  // {
  //   label: "Ingresar",
  //   path: "/ingresar"
  // }
];

const navLinksSignedIn = nombre => [
  {
    label: "Mis aplicaciones",
    path: "/aplicaciones"
  },
  {
    label: "Mi perfil",
    path: "/perfil"
  },
  {
    label: "Salir",
    path: "/salir"
  }
];

function Header(props) {
  const { profile, signOut } = props;
  let history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  function handleNavClick(link) {
    history.push(link);
  }

  return (
    <header className={style.header}>
      <div className={style.menu}>
        {/* <Hidden smUp>
          <div className={style.menuIcon}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Hidden> */}
        <div
          className={style.logo}
          onClick={e => {
            e.preventDefault();
            history.push("/");
          }}
        >
          Autofica
        </div>
        <div className={style.fill}></div>
        <div>
          <ul className={style.navLinksRoot}>
            {(!(profile.loggedIn && profile.confirmed)
              ? navLinks
              : navLinksSignedIn(profile.fullName)
            ).map(function(link) {
              return (
                <li className={style.navLink}>
                  <a
                    href={link.path}
                    onClick={e => {
                      if (link.label === "Salir") {
                        e.preventDefault();

                        signOut();
                        return;
                      }
                      e.preventDefault();
                      handleNavClick(link.path);
                    }}
                  >
                    {link.label}
                  </a>
                </li>
              );
            })}
          </ul>
        
            <img src={flag} width="30"/>
        </div>
      </div>
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <List>
          {(!profile.loggedIn
            ? navLinks
            : navLinksSignedIn(profile.fullName)
          ).map((link, index) => (
            <ListItem
              button
              key={link.label}
              onClick={e => {
                e.preventDefault();
                handleNavClick(link.path);
              }}
            >
              {/* <ListItemIcon> This adds icons to list items
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </header>
  );
}

export default Header;
