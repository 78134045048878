import React from "react";
import { PUBLIC, PRIVATE, DUAL } from "./RouteTypes";
import { Route, Redirect } from "react-router-dom";
import { checkCookie } from "../cookie";

function isLoggedIn() {
  var a = checkCookie();
  return checkCookie();
}

function PublicRoute(props) {
  // console.log(props);
  return (
    <Route
      render={p => {
        if (props.redirect) return <Redirect to={props.redirect} />;
        else if (isLoggedIn()) {
          return <Redirect to={"/"} />;
        } else return <props.component {...props} />;
      }}
    />
  );
}

function PrivateRoute(props) {
  return (
    <Route
      render={p => {
        if (props.redirect) return <Redirect to={props.redirect} />;
        else if (isLoggedIn()) return <props.component {...props} />;
        else return <Redirect to={"/login"} />;
      }}
    />
  );
}

function DualRoute(props) {
  return (
    <Route
      render={p => {
        if (props.redirect) return <Redirect to={props.redirect} />;
        else if (isLoggedIn()) return <props.private {...props} />;
        else return <props.public {...props} />;
      }}
    />
  );
}

function Subrouter(route) {
  switch (route.type) {
    case PUBLIC:
      return <PublicRoute {...route} />;
    case PRIVATE:
      return <PrivateRoute {...route} />;
    case DUAL:
      return <DualRoute {...route} />;
  }
}

export default Subrouter;
