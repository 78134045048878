import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;
  return { state: form.employment, step: form.step };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 8 });
    },

    dispatch
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
