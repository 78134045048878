import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Card,
  Button,
  Grid,
  InputAdornment,
  TextField
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "70%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: {},
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  },
  label: {
    textAlign: "left",
    font: "500 16px/29px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

function StepCard(props) {
  const { bDate, setBDate, nextStep, step } = props;
  const classes = useStyles();
  const history = useHistory();
  const appConstants = useContext(Constants);
  // const [bDate, setBDate] = useState("");

  // console.log(selected);

  if (step < 5) {
    history.push(`./${step}`);
  }

  if (step === 9) {
    history.push(`/form/gracias`);
  }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={60}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>Informacion Personal</span>
        <hr className={classes.separator} />
        <div className={classes.fill}></div>
        <div className={classes.questions}>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>Fecha de Nacimiento</span>
            </Grid>
            <Grid item xs={12} md={9} alignItems={"center"}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Escribe tu Fecha de nacimiento"
                type="date"
                value={bDate}
                onChange={function(e) {
                  setBDate(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.icon}>
                      <CalendarTodayIcon color="inherit" />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.fill}></div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            disableElevation
            onClick={function() {
              nextStep();
              history.push("./6");
            }}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </>
  );
}

export default StepCard;
