import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state, ownProps) {
  const { form } = state;
  return {
    fullName: form.personal.fullName,
    email: form.personal.email,
    phone: form.personal.phone,
    step: form.step
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    nextStep() {
      dispatch({ type: "NEXT_STEP", path: "step", value: 5 });
    },

    setFullName(value) {
      dispatch({ type: "UPDATE_FORM", path: "personal.fullName", value });
    },
    setEmail(value) {
      dispatch({ type: "UPDATE_FORM", path: "personal.email", value });
    },
    setPhone(value) {
      dispatch({ type: "UPDATE_FORM", path: "personal.phone", value });
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
