import React, { useContext, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Card,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Constants from "context";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({
  prog: {
    width: "70%",
    maxWidth: 1000,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 20,
    height: 5
  },
  root: {
    width: "70%",
    minHeight: 500,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 1000,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardTitle: {
    font: "500 20px/22px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  separator: {
    marginTop: 20,
    display: "block",
    marginLeft: 0,
    marginRight: 0
  },
  button: {
    textTransform: "none",
    height: 80,
    boxSizing: "border-box",
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    fontSize: 16
  },

  buttonSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3
  },

  formButton: {
    height: 56,
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    width: "50%",
    textAlign: "left",
    font: "Bold 16px/29px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fc3863"
    },
    width: 360
  },
  questions: {},
  fill: {
    flex: 1
  },
  buttons: { display: "flex", marginTop: 40 },
  buttonIcon: {
    width: "70px",
    height: "auto",
    marginRight: 20,
    verticalAlign: "middle"
  },
  "@media (max-width: 500px)": {
    prog: {
      width: "90%"
    },
    root: {
      width: "90%",
      padding: 20
    }
  },
  label: {
    textAlign: "left",
    font: "500 16px/29px Roboto",
    letterSpacing: 0,
    color: "#000000"
  }
}));

function StepCard(props) {
  const classes = useStyles();
  const history = useHistory();
  // const appConstants = useContext(Constants);
  const {
    monthlyIncome,
    setMonthlyIncome,
    income3Months,
    setIncome3Months,
    nextStep,
    step
  } = props;

  if (step < 8) {
    history.push(`./${step}`);
  }

  if (step === 9) {
    history.push(`/`);
  }

  return (
    <>
      <LinearProgress
        className={classes.prog}
        value={90}
        variant="determinate"
      />
      <Card className={classes.root} outlined>
        <span className={classes.cardTitle}>Informacion de empleo</span>
        <hr className={classes.separator} />
        <div className={classes.fill}></div>
        <div className={classes.questions}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>
                Especifica tus ingresos mensuales
              </span>
            </Grid>

            <Grid item xs={12} md={9} alignItems={"center"}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={"(Ejemplo: 1000Bs, 500 USD)"}
                value={monthlyIncome}
                onChange={function(e) {
                  setMonthlyIncome(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={3} alignItems={"center"}>
              <span className={classes.label}>
                Haz recibido estos ingresos por los ultimos 3 meses?
              </span>
            </Grid>
            <Grid item xs={12} md={9} alignItems={"center"}>
              <FormControl component="fieldset" className={classes.formControl}>
                {/* <FormLabel component="legend">Gender</FormLabel> */}
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={income3Months}
                  onChange={function(e) {
                    setIncome3Months(e.target.value);
                  }}
                  className={classes.radioRoot}
                >
                  <FormControlLabel value="Si" control={<Radio />} label="Si" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className={classes.fill}></div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            disableElevation
            onClick={function() {
              nextStep()
                .then(function(data) {
                  history.push("/gracias");
                })
                .catch(function(e) {
                  console.log(e);
                });
            }}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </>
  );
}

export default StepCard;
