import { OPEN_SNACK, CLOSE_SNACK } from "store/const/actionTypes";
const initialState = {
  open: false,
  message: "",
  snackType: ""
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACK: {
      const { snackType, message } = action;
      return { open: true, message, snackType };
    }
    case CLOSE_SNACK: {
      return initialState;
    }
    default:
      return state;
  }
}

export default reducer;
