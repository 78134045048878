import { connect } from "react-redux";
import { federatedSignIn, signUp } from "store/actions/auth";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // googleSignIn: dispatch(federatedSignIn("Google"))
    googleSignIn: () => {
      return dispatch(federatedSignIn("Google"));
    },
    facebookSignIn: () => {
      return dispatch(federatedSignIn("Facebook"));
    },
    emailSignUp: (email, password) => {
      return dispatch(signUp(email, password));
    }
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
